<template>
  <v-card>
    <v-card-text>
      <h3 style="margin-bottom:1em">Easy-Read URL</h3>
      <v-row>
        <v-col cols="12" md="5" style="padding-bottom:0">
          <v-text-field v-model="shortName" :error-messages="shortNameErrors" label="Short Name" outlined>
            <template v-slot:append>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="primary">fas fa-question-circle</v-icon>
                </template>
                <div style="max-width:400px">The form system will have a unique link for your form that is also easy to read, based on the short name that you enter here, and the URL to link to your form is shown on the right. Each form must have a unique short name, as well as a unique long name. When you lose focus on this field, the system will check to make sure that the name you have entered is unique. If it is not unique then an error will appear and you will need to change it.</div>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" md="7" style="padding-bottom:0">
          <v-text-field :value="originURL + '/forms/' + shortName" label="Form URL" readonly hide-details>
            <template v-slot:append-outer>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn :href="originURL + '/forms/' + shortName" target="_blank" icon>
                    <v-icon>fal fa-sign-in-alt</v-icon>
                  </v-btn>
                </template>
                <span>Open form in new window</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="5" style="padding-bottom:0">
          <v-checkbox v-model="inList" label="Show Form in Forms List" hint="Makes the form appear in the general forms list" persistent-hint></v-checkbox>
        </v-col>
        <v-col cols="12" md="7" style="padding-bottom:0">
          <v-combobox v-model="group" :items="groupOptions" label="Form Group" :hint="inList ? 'The section in the forms list where this form will be found' : 'The Group label in the Admin page where this form will be found'" outlined></v-combobox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="5" style="padding-top:0;padding-bottom:0">
          <v-checkbox v-model="loginRequired" label="Require Login to access this form"></v-checkbox>
          <v-combobox v-if="loginRequired && 'Technology Services' in userRoles" v-model="accessRoles" :items="roles" label="Who can access the form" hint="Leave blank if all users should have access" persistent-hint multiple outlined chips small-chips deletable-chips>
            <template v-slot:append-outer>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="info">fas fa-question-circle</v-icon>
                </template>
                <span>To add a role not in the list, type in the group name from AD and press enter. Make sure the name exactly matches the group name from AD. You can also enter the name from the Department field.</span>
              </v-tooltip>
            </template>
          </v-combobox>
          <v-select v-else-if="loginRequired" v-model="accessRoles" :items="roles" label="Who can access the form" hint="Leave blank if all users should have access" persistent-hint multiple outlined chips small-chips deletable-chips></v-select>
        </v-col>
        <v-col v-if="loginRequired" cols="12" md="5" style="padding-top:0">
          <v-radio-group v-model="anonymous" hide-details>
            <v-radio :value="false" label="Associate user with submissions"></v-radio>
            <v-radio :value="true" label="Submissions will be anonymous"></v-radio>
          </v-radio-group>
          <v-checkbox v-if="!anonymous" v-model="updatePrevious" hide-details label="Submission should update previous entry">
            <template v-slot:append>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="info">fas fa-question-circle</v-icon>
                </template>
                <div style="max-width:300px">Check this box if each submission by a user should update any previous submission (so only one record will be saved per person), or leave it unchecked if every submission by each user should be saved separately</div>
              </v-tooltip>
            </template>
          </v-checkbox>
        </v-col>
      </v-row>
      <h3 style="margin-top:1em">Submission Access</h3>
      <p>These are the roles or users who can access this form. Search for a user below to add them, or click a user in the list to edit their permissions.</p>
      <v-row>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-text>
              <v-autocomplete v-model="newUser" :items="userList" :search-input.sync="userSearch" :filter="() => true" label="Search for user to add" item-value="pidm" outlined dense hide-details return-object @change="addUser">
                <template v-slot:selection="{ item }">{{ item.name }}</template>
                <template v-slot:item="{ item }">{{ item.name }}</template>
              </v-autocomplete>
            </v-card-text>
            <v-list dense v-if="users.length > 0">
              <v-list-item v-for="({ name, role, notify }, index) in users" :key="'user-' + index" @click="selectedUser = index" :style="index === selectedUser ? 'background-color:#C3C3C3' : ''">
                <v-list-item-content>
                  <v-list-item-title>{{ name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ role }}</v-list-item-subtitle>
                  <v-list-item-subtitle>Notify on submission: {{ notify }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-card-actions v-if="removed.length > 0">
              <v-dialog v-model="showRemoved" width="400">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" text>View Removed Users</v-btn>
                </template>
                <v-card>
                  <v-card-title>Removed Users</v-card-title>
                  <v-card-text>
                    <v-list elevation="1">
                      <v-list-item v-for="{ pidm, name, role, removeDate } in removed" :key="pidm">
                        <v-list-item-content>
                          <v-list-item-title>{{ name }}</v-list-item-title>
                          <v-list-item-subtitle>Had role: {{ role }}</v-list-item-subtitle>
                          <v-list-item-subtitle>Removed: {{ stringFormatDate(removeDate) }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn text @click="showRemoved = false">Close</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <access-block v-if="selectedUser >= 0" v-model="users[selectedUser]" :formFields="formFields" @remove="removeUser"></access-block>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn :disabled="shortNameErrors.length > 0 || users.length === 0" color="success" @click="save">
        <v-icon left>fal fa-save</v-icon>
        Save Changes
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { stringFormatDate } from '../../../helpers/formatters'
export default {
  components: {
    AccessBlock: () => import('./AccessBlock')
  },
  props: {
    formId: String,
    revisionId: String
  },
  setup (props, { root }) {
    const userRoles = computed(() => root.$store.state.roles)
    const originURL = computed(() => document.location.origin)
    const infoDialog = ref(false)
    const shortName = ref('')
    const shortNameErrors = ref([])

    watch(shortName, (val) => {
      let regex = /^[a-zA-Z0-9-]*$/
      if (val.length < 5) {
        shortNameErrors.value = ['Too short (5 character minimum)']
      } else if (!regex.test(val)) {
        shortNameErrors.value = ['Invalid character(s). Only letters, numbers, and dashes.']
      } else if (val.length > 30) {
        shortNameErrors.value = ['Too long (30 character maximum)']
      } else {
        // Check to see if this value is unique
        root.$feathers.service('forms/base').find({ query: { shortName: val } }).then(({ data }) => {
          if (shortName.value === val) {
            // See if there is at least one match, and that the matched name still matches the entered name (i.e. if the server was slower to respond and they have entered a longer name)
            let valid = true
            data.forEach((row) => {
              if (JSON.stringify(row._id) !== JSON.stringify(props.formId)) valid = false
            })
            if (!valid) {
              shortNameErrors.value = ['Short name is already in use']
            } else {
              shortNameErrors.value = []
            }
          }
        })
      }
    })

    const inList = ref(true)
    const group = ref('')
    const groupOptions = ref([])
    onMounted(() => {
      root.$feathers.service('forms/base').find({ query: { $distinct: 'group' } }).then(({ data }) => {
        groupOptions.value = []
        for (const { _id } of data) groupOptions.value.push(_id)
      })
    })

    const loginRequired = ref(false)
    const accessRoles = ref([])
    const anonymous = ref(false)
    const updatePrevious = ref(false)
    const roles = ref(['Student', 'Employee'])

    watch(() => props.formId, () => {
      root.$feathers.service('forms/base').get(props.formId).then(load)
    })
    function load (data) {
      shortName.value = data.shortName
      if ('access' in data) {
        loginRequired.value = 'loginRequired' in data.access ? data.access.loginRequired : false
        accessRoles.value = 'roles' in data.access ? data.access.roles : []
        anonymous.value = 'anonymous' in data.access ? data.access.anonymous : false
        updatePrevious.value = 'updatePrevious' in data.access ? data.access.updatePrevious : false
        users.value = []
        if ('users' in data.access && Array.isArray(data.access.users)) {
          users.value = data.access.users
        }
        removed.value = []
        if ('removed' in data.access && Array.isArray(data.access.removed)) {
          removed.value = data.access.removed
        }
      }
      group.value = data.group
      inList.value = ('inList' in data ? data.inList : true)
    }
    function save () {
      // Only save if the short name is valid
      if (shortNameErrors.value.length === 0 && users.value.length > 0) {
        let groupVal = group.value
        if (typeof (groupVal) === 'object') groupVal = groupVal.value
        let patch = {
          shortName: shortName.value,
          inList: inList.value,
          group: groupVal,
          access: {
            loginRequired: loginRequired.value,
            roles: accessRoles.value,
            anonymous: anonymous.value,
            updatePrevious: updatePrevious.value,
            users: users.value,
            removed: removed.value
          }
        }
        root.$feathers.service('forms/base').patch(props.formId, patch).then(load).then(() => {
          root.$store.dispatch('main/snackbar', { active: true, color: 'success', timeout: 6000, text: 'Form settings have been updated' })
        })
      }
    }

    const users = ref([])
    const newUser = ref({})
    const userSearch = ref('')
    const userList = ref([])
    function addUser () {
      if (newUser.value !== '') {
        // Make sure the user is not in the removed list; if so, remove them from the removed list
        for (let i = 0; i < removed.value.length; i++) {
          if (removed.value[i].pidm === newUser.value.pidm) {
            removed.value.splice(i, 1)
            break
          }
        }
        users.value.push({
          name: newUser.value.name,
          pidm: newUser.value.pidm,
          email: newUser.value.email,
          role: 'Admin',
          notify: 'No',
          notifySettings: []
        })
        newUser.value = null
      }
    }
    watch(userSearch, (val) => {
      if (val != null) {
        let query = { $limit: 10, $sort: { 'name.last': 1, 'name.first': 1 }, $select: ['pidm', 'name', 'email'] }
        let [first, last] = val.split(' ')
        if (last) {
          if (first.substr(-1) === ',') {
            let temp = last
            last = first.substr(0, first.length - 1)
            first = temp
          }
          query['name.last'] = { $regex: last, $options: 'i' }
          query['name.first'] = { $regex: first, $options: 'i' }
        } else {
          if (first.substr(-1) === ',') first = first.substr(0, first.length - 1)
          query.$or = [
            { 'name.last': { $regex: first, $options: 'i' } },
            { 'name.first': { $regex: first, $options: 'i' } }
          ]
        }
        root.$feathers.service('directory/people').find({ query }).then(({ data }) => {
          userList.value = []
          data.forEach((row) => {
            userList.value.push({
              name: row.name.last + ', ' + row.name.first,
              email: row.email,
              pidm: row.pidm,
              role: 'Admin',
              notify: 'No',
              notifySettings: []
            })
          })
        })
      }
    })
    const selectedUser = ref(-1)
    function removeUser () {
      if (selectedUser.value >= 0) {
        const { pidm, name, role } = users.value[selectedUser.value]
        removed.value.push({ pidm, name, role, removeDate: new Date() })
        users.value.splice(selectedUser.value, 1)
        selectedUser.value = -1
      }
    }

    const formFields = ref([])
    watch(() => props.revisionId, () => {
      root.$feathers.service('forms/revision').get(props.revisionId).then(({ sections }) => {
        formFields.value = []
        sections.forEach(({ inputs }) => inputs.forEach(({ label, input, options }) => {
          if (input === 'html-block') return
          formFields.value.push({ value: label, text: label, input, options })
        }))
      })
    })

    const removed = ref([])
    const showRemoved = ref(false)

    return {
      userRoles,
      originURL,
      infoDialog,
      shortName,
      shortNameErrors,
      inList,
      group,
      groupOptions,
      loginRequired,
      accessRoles,
      anonymous,
      updatePrevious,
      roles,
      save,
      users,
      newUser,
      userSearch,
      userList,
      addUser,
      selectedUser,
      removeUser,
      formFields,
      removed,
      showRemoved,
      stringFormatDate
    }
  }
}
</script>
