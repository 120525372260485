var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('h3',{staticStyle:{"margin-bottom":"1em"}},[_vm._v("Easy-Read URL")]),_c('v-row',[_c('v-col',{staticStyle:{"padding-bottom":"0"},attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{attrs:{"error-messages":_vm.shortNameErrors,"label":"Short Name","outlined":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary"}},on),[_vm._v("fas fa-question-circle")])]}}])},[_c('div',{staticStyle:{"max-width":"400px"}},[_vm._v("The form system will have a unique link for your form that is also easy to read, based on the short name that you enter here, and the URL to link to your form is shown on the right. Each form must have a unique short name, as well as a unique long name. When you lose focus on this field, the system will check to make sure that the name you have entered is unique. If it is not unique then an error will appear and you will need to change it.")])])]},proxy:true}]),model:{value:(_vm.shortName),callback:function ($$v) {_vm.shortName=$$v},expression:"shortName"}})],1),_c('v-col',{staticStyle:{"padding-bottom":"0"},attrs:{"cols":"12","md":"7"}},[_c('v-text-field',{attrs:{"value":_vm.originURL + '/forms/' + _vm.shortName,"label":"Form URL","readonly":"","hide-details":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"href":_vm.originURL + '/forms/' + _vm.shortName,"target":"_blank","icon":""}},[_c('v-icon',[_vm._v("fal fa-sign-in-alt")])],1)]}}])},[_c('span',[_vm._v("Open form in new window")])])]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{staticStyle:{"padding-bottom":"0"},attrs:{"cols":"12","md":"5"}},[_c('v-checkbox',{attrs:{"label":"Show Form in Forms List","hint":"Makes the form appear in the general forms list","persistent-hint":""},model:{value:(_vm.inList),callback:function ($$v) {_vm.inList=$$v},expression:"inList"}})],1),_c('v-col',{staticStyle:{"padding-bottom":"0"},attrs:{"cols":"12","md":"7"}},[_c('v-combobox',{attrs:{"items":_vm.groupOptions,"label":"Form Group","hint":_vm.inList ? 'The section in the forms list where this form will be found' : 'The Group label in the Admin page where this form will be found',"outlined":""},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}})],1)],1),_c('v-row',[_c('v-col',{staticStyle:{"padding-top":"0","padding-bottom":"0"},attrs:{"cols":"12","md":"5"}},[_c('v-checkbox',{attrs:{"label":"Require Login to access this form"},model:{value:(_vm.loginRequired),callback:function ($$v) {_vm.loginRequired=$$v},expression:"loginRequired"}}),(_vm.loginRequired && 'Technology Services' in _vm.userRoles)?_c('v-combobox',{attrs:{"items":_vm.roles,"label":"Who can access the form","hint":"Leave blank if all users should have access","persistent-hint":"","multiple":"","outlined":"","chips":"","small-chips":"","deletable-chips":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"info"}},on),[_vm._v("fas fa-question-circle")])]}}],null,false,2006381236)},[_c('span',[_vm._v("To add a role not in the list, type in the group name from AD and press enter. Make sure the name exactly matches the group name from AD. You can also enter the name from the Department field.")])])]},proxy:true}],null,false,2910533626),model:{value:(_vm.accessRoles),callback:function ($$v) {_vm.accessRoles=$$v},expression:"accessRoles"}}):(_vm.loginRequired)?_c('v-select',{attrs:{"items":_vm.roles,"label":"Who can access the form","hint":"Leave blank if all users should have access","persistent-hint":"","multiple":"","outlined":"","chips":"","small-chips":"","deletable-chips":""},model:{value:(_vm.accessRoles),callback:function ($$v) {_vm.accessRoles=$$v},expression:"accessRoles"}}):_vm._e()],1),(_vm.loginRequired)?_c('v-col',{staticStyle:{"padding-top":"0"},attrs:{"cols":"12","md":"5"}},[_c('v-radio-group',{attrs:{"hide-details":""},model:{value:(_vm.anonymous),callback:function ($$v) {_vm.anonymous=$$v},expression:"anonymous"}},[_c('v-radio',{attrs:{"value":false,"label":"Associate user with submissions"}}),_c('v-radio',{attrs:{"value":true,"label":"Submissions will be anonymous"}})],1),(!_vm.anonymous)?_c('v-checkbox',{attrs:{"hide-details":"","label":"Submission should update previous entry"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"info"}},on),[_vm._v("fas fa-question-circle")])]}}],null,false,2006381236)},[_c('div',{staticStyle:{"max-width":"300px"}},[_vm._v("Check this box if each submission by a user should update any previous submission (so only one record will be saved per person), or leave it unchecked if every submission by each user should be saved separately")])])]},proxy:true}],null,false,2911486646),model:{value:(_vm.updatePrevious),callback:function ($$v) {_vm.updatePrevious=$$v},expression:"updatePrevious"}}):_vm._e()],1):_vm._e()],1),_c('h3',{staticStyle:{"margin-top":"1em"}},[_vm._v("Submission Access")]),_c('p',[_vm._v("These are the roles or users who can access this form. Search for a user below to add them, or click a user in the list to edit their permissions.")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',[_c('v-card-text',[_c('v-autocomplete',{attrs:{"items":_vm.userList,"search-input":_vm.userSearch,"filter":function () { return true; },"label":"Search for user to add","item-value":"pidm","outlined":"","dense":"","hide-details":"","return-object":""},on:{"update:searchInput":function($event){_vm.userSearch=$event},"update:search-input":function($event){_vm.userSearch=$event},"change":_vm.addUser},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.name))]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.name))]}}]),model:{value:(_vm.newUser),callback:function ($$v) {_vm.newUser=$$v},expression:"newUser"}})],1),(_vm.users.length > 0)?_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.users),function(ref,index){
var name = ref.name;
var role = ref.role;
var notify = ref.notify;
return _c('v-list-item',{key:'user-' + index,style:(index === _vm.selectedUser ? 'background-color:#C3C3C3' : ''),on:{"click":function($event){_vm.selectedUser = index}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(role))]),_c('v-list-item-subtitle',[_vm._v("Notify on submission: "+_vm._s(notify))])],1)],1)}),1):_vm._e(),(_vm.removed.length > 0)?_c('v-card-actions',[_c('v-dialog',{attrs:{"width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_vm._v("View Removed Users")])]}}],null,false,2887703403),model:{value:(_vm.showRemoved),callback:function ($$v) {_vm.showRemoved=$$v},expression:"showRemoved"}},[_c('v-card',[_c('v-card-title',[_vm._v("Removed Users")]),_c('v-card-text',[_c('v-list',{attrs:{"elevation":"1"}},_vm._l((_vm.removed),function(ref){
var pidm = ref.pidm;
var name = ref.name;
var role = ref.role;
var removeDate = ref.removeDate;
return _c('v-list-item',{key:pidm},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(name))]),_c('v-list-item-subtitle',[_vm._v("Had role: "+_vm._s(role))]),_c('v-list-item-subtitle',[_vm._v("Removed: "+_vm._s(_vm.stringFormatDate(removeDate)))])],1)],1)}),1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.showRemoved = false}}},[_vm._v("Close")])],1)],1)],1)],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[(_vm.selectedUser >= 0)?_c('access-block',{attrs:{"formFields":_vm.formFields},on:{"remove":_vm.removeUser},model:{value:(_vm.users[_vm.selectedUser]),callback:function ($$v) {_vm.$set(_vm.users, _vm.selectedUser, $$v)},expression:"users[selectedUser]"}}):_vm._e()],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"disabled":_vm.shortNameErrors.length > 0 || _vm.users.length === 0,"color":"success"},on:{"click":_vm.save}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fal fa-save")]),_vm._v(" Save Changes ")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }